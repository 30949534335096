.pcmh-content-header .icon {
    -webkit-user-select : none; /* Safari */
    -moz-user-select    : none; /* Firefox */
    -ms-user-select     : none; /* IE10+/Edge */
    user-select         : none; /* Standard */
    color               : white;
}

.textField {
    color : #000 !important;
}

.focused {
    color : #434551;
}

.textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.textField input {
    background-color : #ffffff !important;
}

textarea {
    margin      : 0;
    font-family : inherit;
    font-size   : inherit;
    line-height : inherit;
}

textarea {
    overflow : auto;
    resize   : vertical;
}

textarea.form-textarea {
    height : auto;
}

.form-textarea {
    display          : block;
    width            : 100%;
    height           : calc(1.5em + 0.75rem + 2px);
    padding          : 0.375rem 0.75rem;
    font-size        : 1rem;
    font-weight      : 400;
    line-height      : 1.5;
    color            : #495057;
    background-color : #fff;
    background-clip  : padding-box;
    border           : 1px solid #ffffff;
    transition       : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.saving-notification {
    position  : fixed;
    left      : 65px;
    bottom    : 20px;
    z-index   : 9;
    font-size : 12px;
}

.groups {
    background    : var(--smartpcmh-line-color);
    margin        : 8px 0 0 0;
    border-radius : 6px;
    margin        : 15px 0;
}

.groups .questions {
    padding : 10px 10px 3px 3px;
}

.groups .groups-control {
    border-bottom : solid 1px var(--smartpcmh-text-color);
    padding       : 4px;
}

.groups .icon {
    font-color : var(--smartpcmh-grey-dark);
    font-size  : 1.5em;
    margin     : 0 7px;
    cursor     : pointer;
}

.groups .FormControlLabel > span {
    color          : rgba(0, 0, 0, 0.54);
    font-size      : 12px;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    letter-spacing : 0.00938em;
}

.groups .FormControlLabel > span {
    color          : rgba(0, 0, 0, 0.54);
    font-size      : 14px;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    letter-spacing : 0.00938em;
}

.groups .FormControlLabel > .Checkbox {
    color   : var(--smartpcmh-green);
    padding : 0;
}

.groups .FormControlLabel > .Checkbox.checked {
    color : var(--smartpcmh-green);
}

.groups .MuiAutocomplete-root .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    background-color : #ffffff;
}

.groups .editorHeader {
    background         : var(--smartpcmh-green);
    color              : #fff;
    /*font-family        : "Lato", sans-serif;*/
    font-weight        : bolder;
    text-align         : center;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    padding-bottom     : 10px;
}

.editor-button {
    background     : var(--smartpcmh-green);
    color          : white;
    padding        : 5px 6px;
    box-shadow     : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition     : background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius  : 4px;
    font-weight    : 500;
    line-height    : 1.75;
    letter-spacing : 0.02857em;
    text-transform : uppercase;
    font-family    : "Roboto", "Helvetica", "Arial", sans-serif;
    font-size      : 0.875rem;

}

.warning-text {
    font-size   : 1.2em;
    font-weight : bold;
    color       : red;
}