.assessmentQuestion {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px;
}


.assessmentQuestion .name {
    /*font-family : "Lato", sans-serif;*/
    font-size : 1.2em;
    color     : #535353;
}

.assessmentQuestion .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.assessmentQuestion .editing {
    width : 100%;
}

.assessmentQuestion .photo {
    width  : 100px;
    height : 100px;
}

.assessmentQuestion .fieldHeader {
    /*font-family : "Lato", sans-serif;*/
    font-weight : bolder;
}

.assessmentQuestion label {
    display : block;
    padding : 0;
    margin  : 0;
}

.assessmentQuestion .reducePadding {
    padding : 0 2px;
}

.assessmentQuestion .questions {
    background    : #4da965;
    border-radius : 50%;
    border        : 1px solid #ffffff;
    display       : inline-block;
    color         : white;
    font-size     : 1.0em;
    text-align    : center;
    min-width     : 50px;
    margin-left   : 20px;
}

.assessmentQuestion .questions:before,
.assessmentQuestion .questions:after {
    content        : '\200B';
    display        : inline-block;
    line-height    : 0px;
    padding-top    : 50%;
    padding-bottom : 50%;
}

.assessmentQuestion .questions:before {
    padding-left : 8px;
}

.assessmentQuestion .questions:after {
    padding-right : 8px;
}

